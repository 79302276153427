import { runInAction } from 'mobx';
import { makeSubclassObservable } from 'lib/mobx-utils';
import {
  ExpectedCreditTypeEnum,
  ProgramNameEnum,
  ProgramStageEnum,
  ProgramSubStageEnum,
  QualificationStatusEnum,
  ToDoCardIdEnum,
} from 'lib/constants';
import {
  CreditEstimate,
  CreditEstimateHistory,
  PayrollDataSourceEnum,
  ProgramData,
} from 'lib/interfaces';
import { BaseDashboardStore } from '../BaseDashboardStore';
import { ToDoCardEntity } from '../entities/ToDoCardEntity';
import { RootStore } from 'stores/RootStore';
import {
  GradientImage,
  ResourceCardEntity,
} from 'products/dashboard/entities/ResourceCardEntity';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';
import _ from 'lodash';
import { ResourceCardFilter } from 'products/dashboard/entities/ResourceCardFilter';
import { Program } from 'entities/Program';
import { IsRDCreditProgram } from 'lib/helpers';
import { FormattedProgram } from '../features/savings-module';

export class ModuleStore extends BaseDashboardStore {
  public toDoCards: ToDoCardEntity[] = [];
  public resourceCards: ResourceCardEntity[] = [];
  public loadingCards = false;
  public showDismissModal = false;
  public idToDismiss: ToDoCardIdEnum = '' as ToDoCardIdEnum;
  public showSideDrawer = false;
  public getStartedString = '';
  public payrollProvider = '';
  public showUnifiedCreditPaymentModal = false;
  public showTermAcceptanceModal = false;
  public showSignUpModal = false;
  // Educational Module
  public educationalCurrentIndex = 0;
  public educationalPrevIndex = 0;

  private allToDoCards: ToDoCardEntity[] = [];
  private allResourceCards: ResourceCardEntity[] = [];

  // Savings Module
  public activeProgramsCreditEstimate = 0;
  public totalRedeemedCreditEstimate = 0;
  public savingsModuleState: SavingsModuleState | null = null;
  public loadingSavingsModule = true;

  // Account Module
  public estimateHistory: CreditEstimateHistory[] = [];
  public loadingEstimateHistory = false;
  public showEstimateModalIndex: number | null = null;
  public hasNotAcceptedAllOrderForms = false;

  // existing customer modal
  public showExistingCustomerModal = true;

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeSubclassObservable(this);
  }

  public async initialize(taxYear: number) {
    const response = await this.api.GetAllModules();
    const { data } = response;

    if (data) {
      runInAction(() => {
        if (data.toDoCards?.length) {
          this.allToDoCards = data.toDoCards.map(
            (todoCard) => new ToDoCardEntity(todoCard),
          );
          this.initializeToDoCards(taxYear);
        }
        if (data.resourceCards?.length) {
          this.allResourceCards = data.resourceCards.map(
            (data) => new ResourceCardEntity(data),
          );
          this.initializeResourceCards();
        }
      });
    }
  }

  //-------------------- Resource Cards --------------------
  private evaluateResourceCardFilters = (
    filters: ResourceCardFilter[],
    programs: ProgramData[],
  ): boolean => {
    if (!filters.length) {
      return true;
    }
    return _.some(
      filters.map(
        (filter) =>
          !!programs.find(
            (p) =>
              (!filter.year || p.taxYear === filter.year) &&
              (!filter.programName || p.name === filter.programName) &&
              (!filter.programStage || p.stage === filter.programStage) &&
              (!filter.programSubStage ||
                p.subStage === filter.programSubStage),
          ),
      ),
    );
  };

  public initializeResourceCards() {
    const { programs } = this.rootStore.common.companyStore.currentCompany;

    runInAction(() => {
      this.resourceCards = this.allResourceCards.filter((resourceCard) =>
        this.evaluateResourceCardFilters(
          resourceCard.applicableFilters,
          programs,
        ),
      );
      this.resourceCards.sort((a, b) => a.priority - b.priority);
    });
  }

  /**
   * Returns n random, non-duplicative gradients from the
   * /client/public/images/resources-gradients folder
   *
   * @param n: number of random gradients to return
   * @returns string reference to gradient image
   */
  public static getRandomGradients(n: number): string[] {
    const res: string[] = [];
    const randoms: number[] = [];

    // Unfortunately there isn't a prettier way to get the number of KVPs in an Enum.
    // Enums serialize to strings of keys and values, which ends up being 2x the number of possible enums.
    const numGradientsAvailable = Object.values(GradientImage).length / 2;
    while (randoms.length < n) {
      const r = _.random(1, numGradientsAvailable);
      if (!randoms.includes(r)) {
        randoms.push(r);
      }
    }

    randoms.forEach((r) => {
      res.push(
        `${process.env.PUBLIC_URL}/images/resources-gradients/gradient-${r}.png`,
      );
    });

    return res;
  }

  //-------------------- To Do Cards --------------------
  public async initializeToDoCards(taxYear: number) {
    const companyId = this.company.id;
    const payrollRes = await this.client.GetCompanyPayrollProvider(companyId);

    // Error handling if response returns error for getting payroll provider
    if (payrollRes.errorMsg) {
      datadogLogs.logger.error(
        `Error getting payroll provider for company:${companyId}`,
        logContext({ company: this.company }),
      );
    }

    runInAction(() => {
      this.loadingCards = true;
      this.toDoCards = [];

      if (payrollRes.data?.provider) {
        this.payrollProvider = payrollRes.data?.provider;
      }
    });

    const { programs } = this.rootStore.common.companyStore.currentCompany;
    const nonDQPrograms = programs.filter(
      (program) =>
        program.stage !== ProgramStageEnum.DISQUALIFIED &&
        program.qualificationStatus !== QualificationStatusEnum.DISQUALIFIED,
    );
    const rdPrograms = nonDQPrograms.filter((program) =>
      IsRDCreditProgram(program.name),
    );
    const hasQualifyingProgram = programs.filter(
      (program) =>
        program.stage === ProgramStageEnum.QUALIFYING &&
        program.qualificationStatus ===
          QualificationStatusEnum.QUALIFICATION_IN_PROGRESS,
    );

    // show erc qualification logic - if company founded <= 2021
    const showERCQualification =
      this.rootStore.common.featureFlags.flags.showERCQualification;
    const foundedInBefore2021 = this.company.yearFounded
      ? this.company.yearFounded <= 2021
      : false;

    const finishedQualStage = [
      ProgramStageEnum.DISQUALIFIED,
      ProgramStageEnum.MS_REVIEW,
      ProgramStageEnum.FINISHED,
      ProgramStageEnum.CANCELED,
    ];

    // retirement program show logic
    const retirementProgram = programs.find(
      (p) => p.name === ProgramNameEnum.FED_RETIREMENT_CREDIT,
    );
    const finishedRetirementQual =
      !!retirementProgram &&
      finishedQualStage.includes(retirementProgram?.stage);
    const showRetirementToDoCard =
      this.rootStore.common.featureFlags.flags.showRetirementPlanCredit &&
      !finishedRetirementQual;

    // disabled access program show logic
    const disabledAccessProgram = programs.find(
      (p) => p.name === ProgramNameEnum.FED_DISABLED_ACCESS,
    );
    const finishedDisabledAccessQual =
      !!disabledAccessProgram &&
      finishedQualStage.includes(disabledAccessProgram?.stage);
    const showDisabledAccessToDoCard =
      this.rootStore.common.featureFlags.flags.showRetirementPlanCredit &&
      !finishedDisabledAccessQual;

    // health care program show logic
    const healthCareProgram = programs.find(
      (p) => p.name === ProgramNameEnum.FED_DISABLED_ACCESS,
    );
    const finishedHealthCaresQual =
      !!healthCareProgram &&
      finishedQualStage.includes(healthCareProgram?.stage);
    const showHealthCareToDoCard =
      this.rootStore.common.featureFlags.flags.showRetirementPlanCredit &&
      !finishedHealthCaresQual;

    runInAction(() => {
      if (this.allToDoCards.length) {
        rdPrograms.forEach((program) => {
          const taxCreditToDoCards = this.allToDoCards.map(
            (data) => new ToDoCardEntity(data, program),
          );
          this.filterTaxCreditCards(taxCreditToDoCards, program);
        });

        // Check current year credits
        const currentPrograms = nonDQPrograms.filter(
          (p) => p.taxYear === taxYear,
        );

        if (currentPrograms.length) {
          const taxCreditToDoCards = this.allToDoCards.map(
            (data) => new ToDoCardEntity(data, currentPrograms[0]),
          );

          if (
            currentPrograms.every(
              (p) => p.stage === ProgramStageEnum.CLIENT_REVIEW,
            )
          ) {
            // Client Review if all credits for current year are in Client Review
            const card = taxCreditToDoCards.filter(
              (toDo) => toDo.id === ToDoCardIdEnum.CLIENT_REVIEW,
            );
            this.toDoCards.push(...card);
          } else if (
            // MS Review if all credits for current year are in MS Review
            currentPrograms.every(
              (p) =>
                p.stage === ProgramStageEnum.MS_REVIEW &&
                p.subStage === ProgramSubStageEnum.REVIEW_IN_PROGRESS,
            )
          ) {
            const card = taxCreditToDoCards.filter(
              (toDo) => toDo.id === ToDoCardIdEnum.REVIEW_IN_PROGRESS,
            );
            this.toDoCards.push(...card);
          } else {
            // Display the generic YEA To-do Card if neither the Client Review nor
            // MS Review cards are already displayed
            this.toDoCards.push(
              ...this.filterToDoCards(taxCreditToDoCards).unifiedYEAToDo,
            );
          }
        }

        const allCards = this.allToDoCards.map(
          (data) => new ToDoCardEntity(data),
        );

        if (showERCQualification && foundedInBefore2021) {
          this.toDoCards.push(...this.filterToDoCards(allCards).ERCToDoCard);
        }

        // show retirement plan todo card
        if (showRetirementToDoCard) {
          this.toDoCards.push(
            ...this.filterToDoCards(allCards).retirementPlanToDo,
          );
        }

        // show disabled access todo card
        if (showDisabledAccessToDoCard) {
          this.toDoCards.push(
            ...this.filterToDoCards(allCards).disabledAccessToDo,
          );
        }

        // show health care todo card
        if (showHealthCareToDoCard) {
          this.toDoCards.push(...this.filterToDoCards(allCards).healthCareToDo);
        }

        this.filterDefaultCards(allCards);

        this.toDoCards.sort((a, b) => a.priority - b.priority);
      }

      this.getStartedString =
        hasQualifyingProgram.length > 0 ? "Let's get started" : 'To Do';
      this.loadingCards = false;
    });
  }

  public filterTaxCreditCards(cards: ToDoCardEntity[], program: ProgramData) {
    const filteredProgramToDoCards = this.filterToDoCards(cards).taxCreditToDo;
    const programToDoCard = this.getCurrentTaxCreditToDoCard(
      filteredProgramToDoCards,
      program,
    );

    if (programToDoCard) {
      this.toDoCards.push(...programToDoCard);
    }
  }

  public filterDefaultCards(cards: ToDoCardEntity[]) {
    const filteredDefaultToDoCards =
      this.filterToDoCards(cards).defaultToDoCards;

    filteredDefaultToDoCards.forEach((card) => {
      const defaultToDoCard = this.getCurrentDefaultToDoCards(card);
      if (defaultToDoCard) {
        this.toDoCards.push(defaultToDoCard);
      }
    });
  }

  public filterToDoCards(cards: ToDoCardEntity[]) {
    const defaultCards = [
      ToDoCardIdEnum.INVITE_YOUR_TEAM,
      ToDoCardIdEnum.CONNECT_YOUR_PAYROLL,
      ToDoCardIdEnum.CONNECT_YOUR_ACCOUNTING,
    ];
    const defaultToDoCards = cards.filter((card) =>
      defaultCards.includes(card.id),
    );

    const taxCreditCards = [
      ToDoCardIdEnum.QUALIFY_YOUR_BUSINESS,
      ToDoCardIdEnum.HOORAY_YOURE_QUALIFIED,
      ToDoCardIdEnum.HOORAY_STATE_CREDIT,
      ToDoCardIdEnum.TAX_YEAR_RD_ASSESSMENT,
      ToDoCardIdEnum.REVIEW_IN_PROGRESS,
      ToDoCardIdEnum.CLIENT_REVIEW,
      ToDoCardIdEnum.USE_YOUR_CREDITS,
    ];
    const taxCreditToDo = cards.filter((card) =>
      taxCreditCards.includes(card.id),
    );

    // ERC todo card
    const ERCToDoCard = cards.filter(
      (card) => card.id === ToDoCardIdEnum.ERC_FLOW,
    );

    // retirement plan todo card
    const retirementPlanToDo = cards.filter(
      (card) => card.id === ToDoCardIdEnum.RETIREMENT_CREDIT,
    );

    // disabled access todo card
    const disabledAccessToDo = cards.filter(
      (card) => card.id === ToDoCardIdEnum.DISABLED_ACCESS_CREDIT,
    );

    // disabled access todo card
    const healthCareToDo = cards.filter(
      (card) => card.id === ToDoCardIdEnum.HEALTHCARE_CREDIT,
    );

    const unifiedYEAToDo = cards.filter(
      (card) => card.id === ToDoCardIdEnum.UNIFIED_YEA,
    );

    return {
      taxCreditToDo,
      ERCToDoCard,
      retirementPlanToDo,
      disabledAccessToDo,
      healthCareToDo,
      unifiedYEAToDo,
      defaultToDoCards,
    };
  }

  public getCurrentTaxCreditToDoCard(
    taxCreditToDoCards: ToDoCardEntity[],
    program: ProgramData,
  ) {
    const programStage = program?.stage;
    const programSubStage = program?.subStage;
    const programQualStatus = program?.qualificationStatus;
    const hasOrderForm = program?.orderForm?.acceptedAt === null;

    // State programs
    const statePrograms = [
      ProgramNameEnum.STATE_RD_CA,
      ProgramNameEnum.STATE_RD_AZ,
      ProgramNameEnum.STATE_RD_GA,
      ProgramNameEnum.STATE_RD_MA,
    ];
    if (statePrograms.includes(program.name) && hasOrderForm) {
      const card = taxCreditToDoCards.filter(
        (toDo) => toDo.id === ToDoCardIdEnum.HOORAY_STATE_CREDIT,
      );

      return card;
    }

    // Federal programs
    if (program.name === ProgramNameEnum.FED_RD_TAX) {
      // Accept order form federal
      if (hasOrderForm) {
        const card = taxCreditToDoCards.filter(
          (toDo) => toDo.id === ToDoCardIdEnum.HOORAY_YOURE_QUALIFIED,
        );

        return card;
      }

      // Show qualify to-do card
      if (
        programStage === ProgramStageEnum.QUALIFYING &&
        programQualStatus === QualificationStatusEnum.QUALIFICATION_IN_PROGRESS
      ) {
        const card = taxCreditToDoCards.filter(
          (toDo) => toDo.id === ToDoCardIdEnum.QUALIFY_YOUR_BUSINESS,
        );
        return card;
      }

      const ECSubstages = [
        ProgramSubStageEnum.EXPENSE_CLASSIFICATION_OVERVIEW,
        ProgramSubStageEnum.EXPENSE_CLASSIFICATION_RD_EXPENSES,
        ProgramSubStageEnum.EXPENSE_CLASSIFICATION_COMPANY_DETAILS,
        ProgramSubStageEnum.EXPENSE_CLASSIFICATION_RD_EMPLOYEES,
        ProgramSubStageEnum.EXPENSE_CLASSIFICATION_READY_TO_SUBMIT,
      ];

      // Start R&D assessment
      if (
        programStage === ProgramStageEnum.EXPENSE_CLASSIFICATION &&
        programSubStage &&
        ECSubstages.includes(programSubStage)
      ) {
        const card = taxCreditToDoCards.filter(
          (toDo) => toDo.id === ToDoCardIdEnum.TAX_YEAR_RD_ASSESSMENT,
        );

        return card;
      }

      // Finished stage
      if (
        programStage === ProgramStageEnum.FINISHED &&
        program.filingCreditType === ExpectedCreditTypeEnum.PAYROLL_TAX &&
        program.subStage === ProgramSubStageEnum.READY_TO_REDEEM
      ) {
        const card = taxCreditToDoCards.filter(
          (toDo) => toDo.id === ToDoCardIdEnum.USE_YOUR_CREDITS,
        );

        // hide if payroll provider is one of these: https://mainstreet-com.atlassian.net/browse/COREX-970
        const payrollCreditProviders = ['paylocity', 'adp_run'];

        if (payrollCreditProviders.includes(this.payrollProvider)) {
          return;
        }

        return card;
      }
    }
  }

  public getCurrentDefaultToDoCards(defaultToDoCard: ToDoCardEntity) {
    const currentCompany = this.rootStore.common.companyStore.currentCompany;
    const isNotConnectedPayroll = !currentCompany.linkedPayrollSystem;
    const isNotConnectedAccounting = !currentCompany.linkedAccountingSystem;

    const orgAdmins = this.rootStore.common.auth.members;
    const isDismissed =
      !!currentCompany.misc?.dismissedToDoCards &&
      currentCompany.misc?.dismissedToDoCards.findIndex(
        (toDoId) => toDoId === defaultToDoCard.id,
      ) !== -1;

    // Show connect payroll
    if (
      !isDismissed &&
      isNotConnectedPayroll &&
      defaultToDoCard.id === ToDoCardIdEnum.CONNECT_YOUR_PAYROLL
    ) {
      return defaultToDoCard;
    }
    // Show connect account
    if (
      !isDismissed &&
      isNotConnectedAccounting &&
      defaultToDoCard.id === ToDoCardIdEnum.CONNECT_YOUR_ACCOUNTING
    ) {
      return defaultToDoCard;
    }
    // Show invite
    if (
      !isDismissed &&
      orgAdmins &&
      orgAdmins.length <= 1 &&
      defaultToDoCard.id === ToDoCardIdEnum.INVITE_YOUR_TEAM
    ) {
      return defaultToDoCard;
    }
  }

  public toggleDismissModal(bool: boolean) {
    runInAction(() => (this.showDismissModal = bool));
  }

  public assignDismissId(id: ToDoCardIdEnum) {
    runInAction(() => (this.idToDismiss = id));
  }

  public async dismissCard(toDoCardId: string) {
    // remove the selected card first
    runInAction(() => {
      this.toDoCards = this.toDoCards.filter((card) => card.id !== toDoCardId);
    });

    const companyId = this.rootStore.common.auth.company.id;
    const res = await this.api.DismissToDoCard(toDoCardId);

    if (res.errorMsg) {
      datadogLogs.logger.error(
        `Unable to dismiss to do card:${toDoCardId} for company:${companyId}`,
        logContext({ company: this.company }),
      );
    } else if (res.data) {
      this.assignDismissId('' as ToDoCardIdEnum);
    }
  }

  public toggleSideDrawer(bool: boolean) {
    runInAction(() => (this.showSideDrawer = bool));
  }

  public setEducationalIndex(step: number) {
    runInAction(() => {
      if (this.educationalCurrentIndex !== this.educationalPrevIndex) {
        this.educationalPrevIndex = this.educationalCurrentIndex;
      }
      this.educationalCurrentIndex = step;
    });
  }

  //-------------------- Savings Module --------------------

  /**
   * Uses {@link showDisqualifiedState}, {@link showPreSaleState},
   * {@link showActiveProgramsState}, {@link showCreditsRedeemedState}
   * to determine the Savings Module State
   *
   */
  public setSavingsModuleState() {
    runInAction(() => (this.loadingSavingsModule = true));
    const programs = this.company.programs.sort((a, b) => {
      return b.taxYear - a.taxYear;
    }) as Program[];
    if (programs.length === 0) {
      runInAction(() => (this.savingsModuleState = null));
    } else if (this.showDisqualifiedState(programs)) {
      runInAction(() => {
        this.savingsModuleState = SavingsModuleState.DISQUALIFIED;
      });
    } else if (this.showPreSaleState(programs)) {
      runInAction(() => {
        this.savingsModuleState = SavingsModuleState.PRE_SALE;
      });
    } else if (this.showCreditsRedeemedState(programs)) {
      this.setTotalRedeemedCreditEstimate(programs);
      runInAction(() => {
        this.savingsModuleState = SavingsModuleState.CREDITS_REDEEMED;
      });
    } else if (this.showActiveProgramsState(programs)) {
      runInAction(() => {
        this.savingsModuleState = SavingsModuleState.ACTIVE_PROGRAMS;
      });
    }
    runInAction(() => {
      this.loadingSavingsModule = false;
    });
  }

  /** This is a supporting function for {@link setSavingsModuleState}*/
  private showPreSaleState(programs: Program[]) {
    const orderForms = programs.filter((program) => program.orderForm);

    return orderForms.length === 0;
  }

  /** This is a supporting function for {@link setSavingsModuleState}*/
  private showDisqualifiedState(programs: Program[]) {
    return programs.every(
      (program) =>
        program.stage === ProgramStageEnum.DISQUALIFIED ||
        program.qualificationStatus === QualificationStatusEnum.DISQUALIFIED,
    );
  }

  /** This is a supporting function for {@link setSavingsModuleState}*/
  private showCreditsRedeemedState(programs: Program[]) {
    const mostRecentProgram = programs[0];
    const prevYearPrograms = programs.slice(1);

    return (
      mostRecentProgram.stage === ProgramStageEnum.COMPLETED &&
      prevYearPrograms.every((program) => {
        return (
          !!program.stage &&
          [
            ProgramStageEnum.DISQUALIFIED,
            ProgramStageEnum.CANCELED,
            ProgramStageEnum.COMPLETED,
          ].includes(program.stage)
        );
      })
    );
  }

  /**
   * Calculates total credit estimate for all redeemed programs
   */
  private setTotalRedeemedCreditEstimate(programs: Program[]) {
    const mostRecentProgram = programs[0];
    const fullyRedeemedPrograms = [mostRecentProgram];
    const prevYearPrograms = programs.slice(1);

    prevYearPrograms.forEach((program) => {
      if (program.stage === ProgramStageEnum.COMPLETED) {
        fullyRedeemedPrograms.push(program);
      }
    });

    runInAction(() => {
      this.totalRedeemedCreditEstimate = 0;
      fullyRedeemedPrograms.forEach(async (program) => {
        const latestEstimate =
          await this.rootStore.taxcredits.creditEstimates.getLatestEstimate(
            program.id,
          );
        if (latestEstimate && latestEstimate.estimateCents) {
          this.totalRedeemedCreditEstimate += latestEstimate.estimateCents;
        }
      });
    });
  }

  /**
   * Determines whether the SavingsModule is in the active programs state.
   *
   * Checks from oldest to newest programs for a program that is still in
   * the process of redeeming their credits to facilitate the disqualified state
   * check in {@link setSavingsModuleState}.
   */
  private showActiveProgramsState(programs: Program[]) {
    for (const program of programs) {
      if (this.rootStore.taxcredits.taxCreditsPage.isActiveProgram(program)) {
        return true;
      }
    }
  }

  /**
   * Returns a credit estimate for a given program.
   *
   * Logic: The latest manual estimate will override all estimates for
   * fed programs that are still in the client_review stage after the yea deadline.
   * For fed programs in any other stage and all state programs,
   * the most recent estimate should be used.
   *
   * Returns 0 when no estimates are available.
   */
  public async calculateCreditSavingsEstimate(
    program: Program | FormattedProgram,
  ) {
    const creditEstimateHistory =
      await this.rootStore.taxcredits.creditEstimates.getLatestManualEstimateAfterDeadline(
        program.id,
      );

    const supportedPrograms = [
      ProgramNameEnum.FED_RD_TAX,
      ProgramNameEnum.ERC,
      ProgramNameEnum.FED_EMPLOYER_HEALTHCARE,
      ProgramNameEnum.FED_DISABLED_ACCESS,
      ProgramNameEnum.FED_RETIREMENT_CREDIT,
    ];

    if (supportedPrograms.includes(program.name as ProgramNameEnum)) {
      if (
        program.stage === ProgramStageEnum.MS_REVIEW ||
        program.stage === ProgramStageEnum.CLIENT_REVIEW ||
        program.stage === ProgramStageEnum.FINISHED ||
        program.stage === ProgramStageEnum.COMPLETED
      ) {
        return program.creditAmountCents;
      }

      if (creditEstimateHistory && creditEstimateHistory.latestManualEstimate) {
        const currentAmount =
          creditEstimateHistory.latestManualEstimate.estimateCents;

        return currentAmount;
      } else {
        return (
          program.orderForm?.estimatedTotalCreditCents ??
          program.creditAmountCents
        );
      }
    }

    if (program.name === ProgramNameEnum.STATE_RD_CA) {
      if (
        program.stage === ProgramStageEnum.MS_REVIEW ||
        program.stage === ProgramStageEnum.CLIENT_REVIEW ||
        program.stage === ProgramStageEnum.FINISHED ||
        program.stage === ProgramStageEnum.COMPLETED
      ) {
        return program.creditAmountCents;
      }

      if (program.orderForm?.estimatedTotalCreditCents) {
        return program.orderForm.estimatedTotalCreditCents;
      } else {
        return (
          program.creditAmountCents ??
          creditEstimateHistory?.latestManualEstimate.estimateCents
        );
      }
    }

    return 0;
  }

  /**
   * Calculates total credit estimate for all active programs
   */
  public async setActiveProgramsCreditEstimate(activePrograms: Program[]) {
    const { id: companyId } = this.company;
    let totalEstimate = 0;

    for (const program of activePrograms) {
      if (
        program.stage === ProgramStageEnum.CLIENT_REVIEW ||
        program.stage === ProgramStageEnum.FINISHED ||
        program.stage === ProgramStageEnum.QUALIFYING ||
        program.stage === ProgramStageEnum.EXPENSE_CLASSIFICATION ||
        program.stage === ProgramStageEnum.MS_REVIEW
      ) {
        totalEstimate += await this.calculateCreditSavingsEstimate(program);
      } else {
        datadogLogs.logger.error(
          `Company ${companyId}'s program ${program} has hit an unaccounted for stage: ${program.stage} `,
        );
      }
    }
    runInAction(() => {
      this.activeProgramsCreditEstimate = totalEstimate;
      this.loadingSavingsModule = false;
    });
  }

  //-------------------- Credit Estimates Module --------------------

  /**
   * Sets the creditEstimate history shown on the dashboard
   */
  public async setEstimateHistory(programs: Program[]) {
    runInAction(() => (this.loadingEstimateHistory = true));

    const doesNotHaveOrderForm =
      programs.filter((p) => p.orderForm === null).length > 0;
    const hasNotAcceptedAllOrderForms =
      !doesNotHaveOrderForm &&
      programs.some((p) => p.orderForm?.acceptedAt === null);

    const estimateLogs: CreditEstimateHistory[] = [];

    for (const program of programs) {
      const res =
        await this.rootStore.taxcredits.creditEstimates.api.GetCreditEstimates(
          program.id,
        );

      if (res?.errorMsg) {
        datadogLogs.logger.error(
          `[CREDIT_ESTIMATE]: Error retreiving credit estimates for program_id: ${program.id}`,
          logContext({
            company: this.rootStore.common.companyStore.currentCompany,
            error: res.errorMsg,
          }),
        );
      }

      if (res?.data && res?.data?.creditEstimates.length > 0) {
        const filteredEstimates: CreditEstimate[] = [];
        // Filter out duplicates
        // TODO - Remove as part of COREX-1198.
        const dedupEstimatesBasedOnCreated = res?.data?.creditEstimates.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.created === item.created),
        );

        const estimate6765 = dedupEstimatesBasedOnCreated.filter(
          (item) =>
            item.payrollDataSource === PayrollDataSourceEnum.FORM_6765_ESTIMATE,
        );

        let non6765Estimates = dedupEstimatesBasedOnCreated.filter(
          (item) =>
            item.payrollDataSource !== PayrollDataSourceEnum.FORM_6765_ESTIMATE,
        );

        // display only the latest 6765 estimate
        if (
          estimate6765.length > 0 &&
          (program.stage === ProgramStageEnum.CLIENT_REVIEW ||
            program.stage === ProgramStageEnum.FINISHED ||
            program.stage === ProgramStageEnum.COMPLETED)
        ) {
          const latest6765Estimate = estimate6765.reduce((latest, curr) => {
            if (
              curr.created &&
              latest.created &&
              curr.created > latest.created
            ) {
              return curr;
            } else {
              return latest;
            }
          });

          non6765Estimates = [...non6765Estimates, latest6765Estimate];
        }

        const groupEstimates = non6765Estimates.reduce<
          Record<number, CreditEstimate[]>
        >((acc, item) => {
          const amount = item.estimateCents;
          if (!acc[amount]) {
            acc[amount] = [];
          }

          acc[amount].push(item);
          return acc;
        }, {});

        for (const amount in groupEstimates) {
          const hasProperty = groupEstimates.hasOwnProperty.call(
            groupEstimates,
            amount,
          );

          if (hasProperty) {
            const values = groupEstimates[amount];
            const earliest = values.reduce((acc, item) => {
              return item.created && acc.created && item.created < acc.created
                ? item
                : acc;
            });
            filteredEstimates.push(earliest);
          }
        }

        filteredEstimates.sort((a, b) =>
          b.created && a.created
            ? new Date(b.created).getTime() - new Date(a.created).getTime()
            : b.id - a.id,
        );

        // TODO - Remove as part of COREX-1198.
        if (filteredEstimates.length < res?.data?.creditEstimates.length) {
          datadogLogs.logger.warn(
            'Duplicate credit estimates found in credit estimate history.',
            logContext({ company: this.company }),
          );
        }

        estimateLogs.push({
          program: program,
          creditHistory: filteredEstimates,
        });
      } else {
        if (
          program.orderForm !== null &&
          program.orderForm?.acceptedAt !== null
        ) {
          const orderFormEstimate = [
            {
              id: program.id,
              programId: program.id,
              estimateCents: program.orderForm?.estimatedTotalCreditCents,
              payrollDataSource: PayrollDataSourceEnum.MANUAL_PAYROLL_ESTIMATE,
              created: program.orderForm?.acceptedAt,
            },
          ] as CreditEstimate[];

          estimateLogs.push({
            program: program,
            creditHistory: orderFormEstimate,
          });
        }
      }
    }

    runInAction(() => {
      this.estimateHistory = estimateLogs;
      this.hasNotAcceptedAllOrderForms =
        doesNotHaveOrderForm || hasNotAcceptedAllOrderForms;
    });

    runInAction(() => (this.loadingEstimateHistory = false));
  }

  public setShowEstimateModalIndex(index: number | null) {
    runInAction(() => (this.showEstimateModalIndex = index));
  }

  //-------------------- Unified Credit Payment Modal --------------------
  public toggleShowUnifiedCreditPaymentModal(bool: boolean) {
    runInAction(() => (this.showUnifiedCreditPaymentModal = bool));
  }

  //-------------------- Term Acceptance Module --------------------
  public toggleTermAcceptanceModal(bool: boolean) {
    runInAction(() => (this.showTermAcceptanceModal = bool));
  }

  public setShowExistingCustomerModal(bool: boolean) {
    runInAction(() => (this.showExistingCustomerModal = bool));
  }

  //-------------------- Welcome Sign Up Modal --------------------
  public toggleSignUpModal(bool: boolean) {
    runInAction(() => (this.showSignUpModal = bool));
  }
}

export enum SavingsModuleState {
  ACTIVE_PROGRAMS = 'active_programs',
  DISQUALIFIED = 'disqualified',
  PRE_SALE = 'pre_sale',
  CREDITS_REDEEMED = 'credits_redeemed',
}
