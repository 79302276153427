import React from 'react';
import { Page, UnifiedAccountCreationStepsIndex } from 'lib/constants';
import { useAccountCreationStores, useFeatureFlags } from 'stores/useStores';
import {
  BusinessActivitiesStep,
  BusinessStep,
  PayrollDataStep,
  TaxesStep,
} from '../steps';

interface AccountCreationRoutesInterface {
  exact?: boolean;
  path?: string;
  component: React.ReactElement | JSX.Element;
}

export const useAccountCreationRoutes = (): {
  accountCreationRoutes: AccountCreationRoutesInterface[];
} => {
  const { accountCreation } = useAccountCreationStores();
  const featureFlags = useFeatureFlags();

  const showProgramTaxYearPage = featureFlags.showOnboardingProgramTaxYearPage;

  /// Processing Tax Year Feature Flag value
  const PROCESSING_TAX_YEAR = featureFlags.currentProcessingTaxYear;

  return {
    accountCreationRoutes: [
      {
        exact: true,
        path: `/${Page.accountCreationBusiness}`,
        component: (
          <BusinessStep
            pathTo={`/${Page.accountCreationTaxes}`}
            onNext={() =>
              accountCreation.setCurrentStep(
                UnifiedAccountCreationStepsIndex.TAXES,
              )
            }
          />
        ),
      },
      {
        path: `/${Page.accountCreationTaxes}`,
        component: (
          <TaxesStep
            pathTo={
              showProgramTaxYearPage
                ? `/${Page.accountCreationPrograms}`
                : `/${Page.accountCreationPayrollData}`
            }
            pathFrom={`/${Page.accountCreationBusiness}`}
            onNext={() =>
              showProgramTaxYearPage
                ? accountCreation.setCurrentStep(
                    UnifiedAccountCreationStepsIndex.PROGRAMS,
                  )
                : accountCreation.setCurrentStep(
                    UnifiedAccountCreationStepsIndex.PAYROLL_DATA,
                  )
            }
            onBack={() =>
              accountCreation.setCurrentStep(
                UnifiedAccountCreationStepsIndex.BUSINESS,
              )
            }
            taxYear={PROCESSING_TAX_YEAR}
          />
        ),
      },
      {
        path: `/${Page.accountCreationPayrollData}`,
        component: (
          <PayrollDataStep
            pathTo={`/${Page.accountCreationBusinessActivities}`}
            pathFrom={`/${Page.accountCreationTaxes}`}
            onNext={() =>
              accountCreation.setCurrentStep(
                UnifiedAccountCreationStepsIndex.BUSINESS_ACTIVITIES,
              )
            }
            onBack={() =>
              accountCreation.setCurrentStep(
                UnifiedAccountCreationStepsIndex.TAXES,
              )
            }
            taxYear={PROCESSING_TAX_YEAR}
          />
        ),
      },
      {
        path: `/${Page.accountCreationBusinessActivities}`,
        component: (
          <BusinessActivitiesStep
            pathTo={`/${Page.accountCreationCreditEstimate}`}
            pathFrom={`/${Page.accountCreationPayrollData}`}
            onBack={() =>
              accountCreation.setCurrentStep(
                UnifiedAccountCreationStepsIndex.PAYROLL_DATA,
              )
            }
            taxYear={PROCESSING_TAX_YEAR}
          />
        ),
      },
    ],
  };
};
