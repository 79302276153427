import React, { useEffect, useMemo, useState } from 'react';
import {
  Animate,
  AnswerType,
  AnswerValueType,
  Color,
  Flex,
  Spinner,
  SurveyQuestion,
} from 'component-library';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useSurveyQuestions } from 'lib/useSurveyQuestions';
import {
  AttestationEventTypeEnum,
  HealthcarePlanCreditQuestions,
  HealthcarePlanCreditQuestionsToAnswers,
  Page,
  ProgramNameEnum,
  ProgramStageEnum,
  ProgramSubStageEnum,
  QualificationModalStatusEnum,
  SurveyNameEnum,
} from 'lib/constants';
import { CmsQuestionData, CmsRenderTree } from 'lib/interfaces';
import {
  useCommonStores,
  useCompany,
  useDashboardStores,
  useFeatureFlags,
  useLegacyClients,
  useTaxCreditsStores,
} from 'stores/useStores';
import _ from 'lodash';
import { SurveyFlowContainer } from 'products/tax-credits/components';
import { useEffectOnce } from 'component-library/_helpers/use-utils';
import DOMPurify from 'dompurify';
import { QualificationStatusModal } from 'products/tax-credits/features/retirement-plan/components';
import { PrefillAlert } from '../../components/PrefillAlert';
import {
  AccumulatedModal,
  IncompleteProgramSurveyModal,
  SubmitForReviewModal,
} from '../../components';
import { SurveyAttestation } from 'pages/tax-processing/expense-classification/components/';
import { useExpenseClassificationAnswerPrefill } from 'lib/useExpenseClassificationAnswerPrefill';
import { UnifiedCreditPaymentModal } from '../../../../../../components/payments/UnifiedCreditPaymentModal';
import { ProofOfShopUploaded } from './components/ProofOfShopUploader';
import renderTreeJson from '../../../../../../hygraph/renderTree/unifiedSmallBusinessHealthCareCredit.json';
import { buildCommonHandlers, handleContinueText } from '../commonHandlers';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  mainContent: {
    margin: '0 auto',
    position: 'relative',
  },
  subtitle: {
    fontSize: '15px',
    lineHeight: '24px',
    color: Color.neutral._80,
  },
  hasDropdown: {
    position: 'relative',
    zIndex: 2,
  },
}));

interface UnifiedSmallBusinessHealthcareProps {
  onNext: () => void;
  onBack: () => void;
  taxYear: number;
}

export const UnifiedSmallBusinessHealthcare: React.FC<UnifiedSmallBusinessHealthcareProps> =
  observer(({ onNext, onBack, taxYear }) => {
    const classes = useStyles();
    const [showDqModal, setShowDqModal] = useState<boolean>(false);
    const [missingProofDocument, setMissingProofDocument] =
      useState<boolean>(false);
    const [identifierValidationError, setIdentifierValidationError] =
      useState<boolean>(false);

    const { app, auth, chatbot, companyStore } = useCommonStores();
    const { surveyFlow, unifiedTaxCredits } = useTaxCreditsStores();
    const featureFlags = useFeatureFlags();
    const [hasConfirmedAnswers, setHasConfirmedAnswers] =
      useState<boolean>(false);
    const { company } = useCompany();
    const { modules } = useDashboardStores();
    const program =
      company.programs.find(
        (item) =>
          item.taxYear === taxYear &&
          item.name === ProgramNameEnum.FED_EMPLOYER_HEALTHCARE,
      ) || null;
    const { client } = useLegacyClients();
    const surveyProgram = company.programs.find(
      (p) =>
        p.taxYear === taxYear &&
        p.name === ProgramNameEnum.FED_EMPLOYER_HEALTHCARE,
    );
    const allSurveysViewed = useMemo(() => {
      return companyStore.company.programs
        .filter((program) => program.taxYear === taxYear)
        .every(
          (program) =>
            program.subStage ===
              ProgramSubStageEnum.EXPENSE_CLASSIFICATION_SURVEY_SKIPPED ||
            program.subStage ===
              ProgramSubStageEnum.EXPENSE_CLASSIFICATION_READY_TO_SUBMIT ||
            (program.subStage === null &&
              program.stage === ProgramStageEnum.DISQUALIFIED),
        );
    }, [companyStore.company.programs, taxYear]);
    const [loadingPrefill, setLoadingPrefill] = useState(true);

    const surveyName: SurveyNameEnum =
      SurveyNameEnum.UNIFIED_SMALL_BUSINESS_HEALTHCARE_SURVEY;

    const {
      isSurveyComplete,
      isLoading,
      surveyAnswers,
      questionsToRender,
      addSurveyAnswers,
      saveAnswers,
      addSurveyAnswersWithoutRerender,
    } = useSurveyQuestions(
      surveyName,
      taxYear,
      {},
      renderTreeJson as unknown as CmsRenderTree,
    );

    // Prefill answers from other EC/Qual questions
    useExpenseClassificationAnswerPrefill(
      surveyName,
      program,
      addSurveyAnswersWithoutRerender,
    );

    useEffectOnce(async () => {
      // create program if doesn't exist
      await surveyFlow.createTaxCreditProgram(
        taxYear,
        ProgramNameEnum.FED_EMPLOYER_HEALTHCARE,
      );
      // update program stage to expense_classification onload
      await unifiedTaxCredits.updateProgramStageOnSurveyEntry(
        taxYear,
        ProgramNameEnum.FED_EMPLOYER_HEALTHCARE,
      );

      await client.SetYeaPrefill(taxYear);
      await app.common.companyStore.refreshCurrentCompany();
      setLoadingPrefill(false);
    });

    useEffect(() => {
      // scroll page down as user answers each question
      scrollIntoViewOnKeyDown();

      // checking survey answers to display qualification modal
      surveyFlow.checkQualificationStatus(
        surveyAnswers,
        SurveyNameEnum.UNIFIED_SMALL_BUSINESS_HEALTHCARE_SURVEY,
        taxYear,
      );

      const hasHealthcareDq = surveyFlow.allQualificationStatuses.find(
        (status) =>
          status.status === QualificationModalStatusEnum.HEALTHCARE_DQ,
      );

      if (hasHealthcareDq) {
        setShowDqModal(true);
      } else {
        setShowDqModal(false);
      }

      // edge case where user uploaded and deletes document
      // next question shows and is able to continue
      // this shows a missing doc error preventing user from submitting survey
      if (
        surveyAnswers &&
        surveyAnswers[HealthcarePlanCreditQuestions.PROOF_OF_SHOP_DOC]
      ) {
        if (
          surveyAnswers[
            HealthcarePlanCreditQuestions.DID_YOU_PAY_50PERCENT_HEALTH_INSURANCE
          ] ===
            HealthcarePlanCreditQuestionsToAnswers[
              HealthcarePlanCreditQuestions
                .DID_YOU_PAY_50PERCENT_HEALTH_INSURANCE
            ].UNSURE ||
          surveyAnswers[HealthcarePlanCreditQuestions.MARKET_IDENTIFIER] ===
            'n/a' ||
          surveyAnswers[HealthcarePlanCreditQuestions.MARKET_IDENTIFIER] ===
            'N/A'
        ) {
          if (
            surveyAnswers[HealthcarePlanCreditQuestions.PROOF_OF_SHOP_DOC]
              .length === 0
          ) {
            setMissingProofDocument(true);
          } else {
            setMissingProofDocument(false);
          }
        }
      }
    }, [surveyFlow, surveyAnswers, taxYear]);

    useEffectOnce(() => {
      if (featureFlags.showShareAssessment) {
        unifiedTaxCredits.setShareAssessmentOnNext(onNext);
      }
    });

    const { handleOnContinue, handleOnDisqualified } = buildCommonHandlers(
      surveyFlow,
      unifiedTaxCredits,
      companyStore,
      auth,
      modules,
      app,
      client,
      taxYear,
      surveyProgram,
      QualificationModalStatusEnum.HEALTHCARE_DQ,
      AttestationEventTypeEnum.YEAR_END_ASSESSMENT_BUSINESS_HEALTHCARE,
      hasConfirmedAnswers,
      featureFlags.showSurveyReviewPage,
      featureFlags.saveYeaSurveyAttestation,
      onNext,
    );

    const continueText = handleContinueText(
      companyStore.accessToken,
      allSurveysViewed,
      'Continue to R&D Tax credit: Company Details',
    );

    const handleAnswerChange = (
      question: CmsQuestionData,
      answerValue: AnswerValueType,
      key: string,
    ) => {
      if (
        question.id === HealthcarePlanCreditQuestions.MARKET_IDENTIFIER &&
        identifierValidationError
      ) {
        setIdentifierValidationError(false);
      }
      if (
        question.id === HealthcarePlanCreditQuestions.AMOUNT_YOU_RECEIVED &&
        answerValue === 0
      ) {
        question.answerValue = answerValue;
        addSurveyAnswers(question.id, key, [
          { questionId: question.id, answerValue: answerValue },
        ]);
      } else {
        if (
          question.id ===
            HealthcarePlanCreditQuestions.RECEIVED_STATE_PREMIUM_SUBSIDIES &&
          answerValue ===
            HealthcarePlanCreditQuestionsToAnswers[
              HealthcarePlanCreditQuestions.RECEIVED_STATE_PREMIUM_SUBSIDIES
            ].NO
        ) {
          addSurveyAnswers(
            HealthcarePlanCreditQuestions.AMOUNT_YOU_RECEIVED,
            key,
            [
              {
                questionId: HealthcarePlanCreditQuestions.AMOUNT_YOU_RECEIVED,
                answerValue: undefined,
              },
            ],
          );

          surveyAnswers[HealthcarePlanCreditQuestions.AMOUNT_YOU_RECEIVED] =
            null;

          question.answerValue = answerValue;
          addSurveyAnswers(question.id, key, [
            { questionId: question.id, answerValue: answerValue },
          ]);
          saveAnswers && saveAnswers();
        } else {
          question.answerValue = answerValue;
          addSurveyAnswers(question.id, key, [
            { questionId: question.id, answerValue: answerValue },
          ]);
          // save answers per question answered
          if (
            question.answerType === 'yes_or_no' ||
            question.answerType === 'yes_no_or_unknown'
          ) {
            saveAnswers && saveAnswers();
          }
        }
      }
    };

    const scrollIntoViewOnKeyDown = () => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    };

    const checkMarketplaceIdendifier = (
      answerValue: string | number | string[] | undefined,
    ) => {
      // Check if input is a string
      if (typeof answerValue !== 'string') {
        return;
      }

      // Check if it is "n/a" or "N/A"
      if (answerValue.toLowerCase() === 'n/a') {
        return;
      }

      // Check length
      if (answerValue.length !== 14) {
        setIdentifierValidationError(true);
        return;
      }

      // Check if it contains only letters and numbers
      if (!/^[a-zA-Z0-9]+$/.test(answerValue)) {
        setIdentifierValidationError(true);
        return;
      }

      setIdentifierValidationError(false);
    };

    const renderQuestions = () =>
      Object.keys(questionsToRender).map((key) => {
        return questionsToRender[key].map((question) => {
          const subtitleHtml =
            question.subtitle && question.subtitle.includes('<br/>');

          const determineZeroDollar =
            (question.id ===
              HealthcarePlanCreditQuestions.TOTAL_AMOUNT_PAID_FOR_HEALTH_INSURANCE ||
              question.id ===
                HealthcarePlanCreditQuestions.TOTAL_EMPLOYEES_WAGES_PAID_IN_TAX_YEAR ||
              question.id ===
                HealthcarePlanCreditQuestions.AMOUNT_YOU_RECEIVED) &&
            question.answerValue === 0;

          let helpLink = '';
          switch (question.id) {
            case 'clp0a5geuanjq0bn4omeckuu3':
              helpLink =
                'https://mainstreet1.my.site.com/help/s/article/What-is-a-Small-Business-Health-Options-Program-SHOP-Marketplace';
              break;
            case 'clp0a66i80civ0blqst3dhmp1':
              helpLink =
                'https://mainstreet1.my.site.com/help/s/article/How-to-find-your-SHOP-Marketplace-Plan-ID';
              break;
            case 'clp0aadh2apkt0bn4lgm2kw28':
              helpLink =
                'https://mainstreet1.my.site.com/help/s/article/How-to-determine-the-Full-time-Equivalent-FTE-Employee';
              break;
          }

          const handleError = (id: string) => {
            if (
              id === HealthcarePlanCreditQuestions.AMOUNT_YOU_RECEIVED &&
              determineZeroDollar
            ) {
              return 'This value cannot be $0';
            } else if (
              id === HealthcarePlanCreditQuestions.MARKET_IDENTIFIER &&
              identifierValidationError
            ) {
              return 'Please enter a valid Marketplace Identifier (14-character combination of numbers and/or letters)';
            } else {
              return '';
            }
          };

          if (
            question.id === HealthcarePlanCreditQuestions.PROOF_OF_SHOP_DOC &&
            program
          ) {
            return (
              <Animate
                key={question.id}
                enter={['fade-in', 'from-bottom']}
                duration={0.5}
              >
                <ProofOfShopUploaded
                  question={question}
                  program={program}
                  saveAnswers={saveAnswers}
                  handleAnswerChange={(answer) =>
                    handleAnswerChange(question, answer, key)
                  }
                  missingProofDocument={missingProofDocument}
                />
              </Animate>
            );
          }

          return (
            <Animate
              key={question.id}
              enter={['fade-in', 'from-bottom']}
              duration={0.5}
              className={`${
                question.answerType === 'dropdown_select'
                  ? classes.hasDropdown
                  : ''
              }`}
            >
              <SurveyQuestion
                dataTestId={question.id}
                answerType={question.answerType as AnswerType}
                text={question.text}
                subtitle={
                  subtitleHtml ? (
                    <div
                      className={classes.subtitle}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(question.subtitle),
                      }}
                    />
                  ) : (
                    question.subtitle
                  )
                }
                answerOptions={question.answerIDs}
                onChange={(answer) => handleAnswerChange(question, answer, key)}
                onBlur={() => {
                  if (
                    question.id ===
                      HealthcarePlanCreditQuestions.AMOUNT_YOU_RECEIVED &&
                    question.answerValue === 0
                  ) {
                    return;
                  } else if (
                    question.id ===
                    HealthcarePlanCreditQuestions.MARKET_IDENTIFIER
                  ) {
                    checkMarketplaceIdendifier(question.answerValue);
                  } else {
                    saveAnswers && saveAnswers();
                  }
                }}
                placeholder={question.placeholder}
                answerValue={question.answerValue}
                tooltip={question.tooltip}
                questionTextSize={app.isMobile ? 15 : 18}
                questionSubtitleSize={app.isMobile ? 13 : 15}
                withCardMargin={false}
                helpLinkText={question.helperText}
                chatBotQuestion={chatbot.isAiQuestionEnabled(
                  question.aiQuestion,
                )}
                chatBotPrompt={() =>
                  chatbot.autoSendAiMessage(question.aiQuestion)
                }
                error={handleError(question.id).length > 0}
                errorText={handleError(question.id)}
                onHelpLinkClick={helpLink}
              />
            </Animate>
          );
        });
      });

    return (
      <Flex
        direction='column'
        className={classes.root}
        data-testid={'unified-small-business-healthcare-credit'}
      >
        <Flex className={classes.mainContent} direction='column'>
          <Animate enter={'fade-in'}>
            <SurveyFlowContainer
              title='Small Business Healthcare Tax Credit'
              onContinue={handleOnContinue}
              isDisabled={
                !isSurveyComplete ||
                (featureFlags.saveYeaSurveyAttestation && !hasConfirmedAnswers)
              }
              isLoading={surveyFlow.surveyContinueLoading}
              continueText={continueText}
              currentPage={Page.healthcare}
              onBack={companyStore.accessToken ? undefined : onBack}
              onSkip={() =>
                surveyFlow.skipSurveyStep(
                  taxYear,
                  program?.id ?? -1,
                  ProgramNameEnum.FED_EMPLOYER_HEALTHCARE,
                  onNext,
                )
              }
            >
              <Flex direction='column' gap={24}>
                <PrefillAlert />
                {isLoading || loadingPrefill ? (
                  <Spinner size='small' />
                ) : (
                  !_.isEmpty(questionsToRender) && renderQuestions()
                )}
                {showDqModal && (
                  <QualificationStatusModal
                    showModal={surveyFlow.showQualificationStatusModal}
                    modalOnClick={async () => {
                      await handleOnDisqualified();
                    }}
                    programName={ProgramNameEnum.FED_EMPLOYER_HEALTHCARE}
                  />
                )}
                {featureFlags.saveYeaSurveyAttestation && (
                  <SurveyAttestation
                    checked={hasConfirmedAnswers}
                    onAttestation={() =>
                      setHasConfirmedAnswers(!hasConfirmedAnswers)
                    }
                  />
                )}
              </Flex>
            </SurveyFlowContainer>
          </Animate>
        </Flex>
        {/* if user edit and is ready to submit - show modal */}
        {!featureFlags.showSurveyReviewPage && (
          <>
            <SubmitForReviewModal
              taxYear={taxYear}
              onNext={() => app.history.push(`/${Page.taxCredits}`)}
            />
            <UnifiedCreditPaymentModal
              taxYear={taxYear}
              onNext={() => unifiedTaxCredits.setShowSubmitReviewModal(true)}
            />

            {unifiedTaxCredits.assessmentByYear[taxYear] && (
              <IncompleteProgramSurveyModal
                taxYear={taxYear}
                assessments={unifiedTaxCredits.assessmentByYear[taxYear]}
              />
            )}
          </>
        )}
        <AccumulatedModal
          programName='Small Business Healthcare'
          subtitle='Keep the momentum going—more tax savings are just around
                the corner.'
        />
      </Flex>
    );
  });
