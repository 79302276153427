import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { ContentContainer } from '../component';
import { observer } from 'mobx-react';
import {
  useAccountCreationStores,
  useCommonStores,
  useHistoryStore,
} from 'stores/useStores';
import {
  Text,
  SurveyQuestion,
  Flex,
  Spinner,
  AnswerType,
} from 'component-library';
import { useEffectOnce } from 'lib/helpers';
import { GraphCmsQuestionIdEnum, SurveyNameEnum } from 'lib/constants';
import { useSurveyQuestions } from 'lib/useSurveyQuestions';
import { AccountCreationSteps, CmsRenderTree } from 'lib/interfaces';
import renderTreeJson from '../../../../../hygraph/renderTree/unifiedAccountCreation.json';

const useStyles = makeStyles(() => ({
  surveyQuestion: {
    '& button': {
      padding: '12px 15px',
    },
  },
  '@global': {
    '.SurveyQuestion__title-section___2bPTK.SurveyQuestion__no-subtitle___eX6M3':
      {
        marginBottom: 0, // fix title spacing for SurveyQuestion
      },
  },
}));
interface PayrollDataStepProps {
  pathTo: string;
  pathFrom: string;
  onNext: () => void;
  onBack: () => void;
  taxYear: number;
}

export const PayrollDataStep = observer(
  ({ pathTo, pathFrom, onNext, onBack, taxYear }: PayrollDataStepProps) => {
    const classes = useStyles();
    const { accountCreation } = useAccountCreationStores();
    const [isWageValueZero, setIsWageValueZero] = useState<boolean>(false);
    const { app } = useCommonStores();
    const history = useHistoryStore();
    const { saveAnswers, isLoading, questionsToRender, addSurveyAnswers } =
      useSurveyQuestions(
        SurveyNameEnum.UNIFIED_ACCOUNT_CREATION_SURVEY,
        taxYear,
        {},
        renderTreeJson as CmsRenderTree,
      );

    const onContinue = async () => {
      accountCreation.isNextButtonLoading = true;
      await saveAnswers();

      // handle creating state program credits
      await accountCreation.handleCreateStatePrograms(taxYear);

      await accountCreation.updateAccount(
        {},
        AccountCreationSteps.PAYROLL_DATA,
        true, // is unified account creation
      );

      if (!accountCreation.errorMsg) {
        history.push(pathTo);
        onNext();
      }
      accountCreation.isNextButtonLoading = false;
    };

    useEffectOnce(() => accountCreation.setTaxesStepQuestions());

    return (
      <ContentContainer
        onBack={onBack}
        onNext={onContinue}
        isNextButtonDisabled={
          !questionsToRender['payroll']?.every(
            (question) =>
              question.answerValue !== undefined &&
              question.answerValue !== null,
          ) || isWageValueZero
        }
        isNextLoading={accountCreation.isNextButtonLoading}
        pathFrom={pathFrom}
      >
        <Flex alignItems='start' direction='column' gap={8}>
          <Text
            text='Estimate your payroll spend'
            variant='medium'
            size={app.isMobile ? 23 : 32}
          />
          <Text size={15}>
            Don&apos;t worry about exact numbers — estimated information will
            just help us create a personalized estimate.
          </Text>
        </Flex>
        {isLoading || !questionsToRender['payroll'] ? (
          <Flex justifyContent='center'>
            <Spinner />
          </Flex>
        ) : (
          <>
            {questionsToRender['payroll'].map((question) => (
              <SurveyQuestion
                className={classes.surveyQuestion}
                key={question.id}
                placeholder={question.placeholder}
                text={question.text}
                questionTextSize={15}
                answerType={question.answerType as AnswerType}
                answerValue={question.answerValue}
                onChange={(answer) => {
                  question.answerValue = answer;

                  if (
                    question.id ===
                      GraphCmsQuestionIdEnum.HOW_MUCH_SPEND_IN_FISCAL_TAX_YEAR &&
                    question.answerValue === 0
                  ) {
                    setIsWageValueZero(true);
                  } else {
                    setIsWageValueZero(false);

                    addSurveyAnswers(question.id, 'payroll', [
                      { questionId: question.id, answerValue: answer },
                    ]);

                    // save answers per question answered
                    if (
                      question.answerType === 'yes_or_no' ||
                      question.answerType === 'multiple_select'
                    ) {
                      saveAnswers && saveAnswers();
                    }
                  }
                }}
                onBlur={() => saveAnswers && saveAnswers()}
                answerOptions={question.answerIDs}
                withCard={false}
                multiSelectDropdown
                error={
                  question.id ===
                    GraphCmsQuestionIdEnum.HOW_MUCH_SPEND_IN_FISCAL_TAX_YEAR &&
                  question.answerValue === 0
                }
                errorText={
                  question.id ===
                    GraphCmsQuestionIdEnum.HOW_MUCH_SPEND_IN_FISCAL_TAX_YEAR &&
                  question.answerValue === 0
                    ? 'This value cannot be $0'
                    : ''
                }
              />
            ))}
          </>
        )}
      </ContentContainer>
    );
  },
);
