import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { Color, Divider, Flex, Heading, Text } from 'component-library';
import { useCompany, useFeatureFlags } from 'stores/useStores';
import {
  ExpectedCreditTypeEnum,
  Programs,
  ProgramStageEnum,
} from 'lib/constants';
import { CentsToDisplayString } from 'lib/helpers';
import confetti from 'canvas-confetti';
import { EmptyClient } from '../../../../../services/ServerClient';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from '../../../../../logging';
import { ProgramData } from '../../../../../lib/interfaces';

const useStyles = makeStyles(() => ({
  root: {
    padding: '40px 40px 80px',
  },
  gridContainer: {
    maxWidth: '1024px',
    width: '100%',
  },
  finalCreditColumn: {
    border: `1px solid ${Color.neutral._20}`,
  },
  finalCredit: {
    backgroundColor: Color.lime._20,
    position: 'relative',
    borderBottom: `1px solid ${Color.neutral._20}`,
  },
  confetti: {
    position: 'absolute',
    top: -60,
    left: -180,
    pointerEvents: 'none',

    '& > canvas': {
      width: 500,
    },
  },
}));

export const FinalEstimateList = observer(() => {
  const classes = useStyles();
  const { company } = useCompany();
  const [programsInReview, setProgramsInReview] = useState<ProgramData[]>([]);
  const [finalAmount, setFinalAmount] = useState<number | undefined>(undefined);
  const featureFlags = useFeatureFlags();

  /// Processing Tax Year Feature Flag value
  const PROCESSING_TAX_YEAR = featureFlags.currentProcessingTaxYear;

  useEffect(() => {
    EmptyClient.GetFinalPaymentTotal(PROCESSING_TAX_YEAR)
      .then((res) => {
        if (res.data && res.data?.programs.length !== 0) {
          const programs: ProgramData[] = res.data.programs
            .filter(
              (p) =>
                (p?.stage === ProgramStageEnum.CLIENT_REVIEW ||
                  p?.stage === ProgramStageEnum.FINISHED) &&
                p.taxYear === PROCESSING_TAX_YEAR,
            )
            .flatMap((p) => (p ? [p] : []));
          const totalCreditAmountCents = programs.reduce(
            (sum, program) =>
              sum +
              (program.creditAmountCents === 0
                ? program.orderForm?.estimatedTotalCreditCents ?? 0
                : program.creditAmountCents),
            0,
          );
          setProgramsInReview(programs);
          setFinalAmount(totalCreditAmountCents);
          const confettiCanvas = document.createElement('canvas');
          const confettiPosition = document.getElementById('confetti');

          if (confettiPosition) {
            const hasCanvasChild = Array.from(confettiPosition.children).some(
              function (child) {
                return child.nodeName === 'CANVAS';
              },
            );

            // only create if canvas doesn't exist
            if (!hasCanvasChild) {
              confettiPosition.appendChild(confettiCanvas);
            }
          }

          const myConfetti = confetti.create(confettiCanvas, {
            useWorker: true,
          });
          myConfetti({
            particleCount: 70,
            spread: 100,
            startVelocity: 10,
            scalar: 0.4,
            gravity: 0.5,
            origin: { y: 0.5, x: 0.5 },
          });
        } else {
          datadogLogs.logger.error(
            `Error fetching payment total and the program length total is zero, Object: ${JSON.stringify(
              res.data,
            )}`,
            logContext({
              error: res.errorMsg,
              company: company,
            }),
          );
        }
      })
      .catch((error) => {
        datadogLogs.logger.error(
          `Error fetching payment total: ${error}`,
          logContext({
            error: error,
            company: company,
          }),
        );
      });
  }, []);

  return (
    <Flex direction='column' className={classes.finalCreditColumn}>
      {finalAmount && (
        <>
          <Flex
            direction='column'
            padding={[16, 24]}
            className={classes.finalCredit}
          >
            <div id='confetti' className={classes.confetti} />
            <Text variant='medium'>Total credit amount</Text>
            <Heading size={32} color={Color.green._60} marginBottom={0}>
              {CentsToDisplayString(finalAmount, 0, 0)}
            </Heading>
          </Flex>
          {programsInReview.map((credit, index) => {
            const creditType =
              credit.filingCreditType === ExpectedCreditTypeEnum.PAYROLL_TAX
                ? 'Payroll tax'
                : 'Income tax';

            return (
              <React.Fragment key={`${credit.id}-${index}`}>
                <Flex direction='column' padding={24}>
                  <Text size={13} color={Color.neutral._80}>
                    Final {Programs[credit.name].displayShort}{' '}
                    credit&nbsp;amount
                  </Text>
                  <Flex gap={8} alignItems='center'>
                    <Text size={18} variant='medium'>
                      {CentsToDisplayString(
                        credit.creditAmountCents === 0
                          ? credit.orderForm?.estimatedTotalCreditCents ?? 0
                          : credit.creditAmountCents,
                        0,
                        0,
                      )}
                    </Text>
                    <Text size={13} variant='italic' color={Color.neutral._60}>
                      ({creditType})
                    </Text>
                  </Flex>
                </Flex>
                {programsInReview.length !== index && (
                  <Divider variant='no-bottom-margin' />
                )}
              </React.Fragment>
            );
          })}
        </>
      )}
    </Flex>
  );
});
