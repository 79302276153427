import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Animate,
  AnswerType,
  AnswerValueType,
  FileHandle,
  FileUpload,
  Flex,
  Spinner,
  SurveyQuestion,
} from 'component-library';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useSurveyQuestions } from 'lib/useSurveyQuestions';
import {
  GraphCmsQuestionIdEnum,
  GraphCmsQuestionIdToAnswers,
  Page,
  ProgramNameEnum,
  ProgramStageEnum,
  ProgramSubStageEnum,
  SurveyNameEnum,
} from 'lib/constants';
import {
  CmsQuestionData,
  CmsRenderTree,
  Document,
  DocumentForUpload,
  DocumentSourceEnum,
} from 'lib/interfaces';
import {
  useCommonStores,
  useFeatureFlags,
  useLegacyClients,
  useTaxCreditsStores,
} from 'stores/useStores';
import _ from 'lodash';
import { SurveyFlowContainer } from 'products/tax-credits/components';
import { PrefillAlert } from '../../components/PrefillAlert';
import { CompanyContext } from 'pages/CompanyRequired';
import { datadogLogs } from '@datadog/browser-logs';
import renderTreeJson from '../../../../../../hygraph/renderTree/unifiedGeneralBusinessDetails.json';
import {
  isFutureYear,
  isValidYearFounded,
} from '../../../../../../lib/validation';
import { useEffectOnce } from 'lib/helpers';
import { handleContinueText } from '../commonHandlers';
import { useExpenseClassificationAnswerPrefill } from 'lib/useExpenseClassificationAnswerPrefill';

const SHORT_YEAR_TAX_RETURN = 'Short Year Tax Return';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  mainContent: {
    margin: '0 auto',
    position: 'relative',
  },
  dropdownSelect: {
    zIndex: 5,
  },
}));

interface GeneralBusinessDetailsProps {
  onNext: () => void;
  taxYear: number;
}

interface ShortYearTaxReturnDocumentForUpload extends DocumentForUpload {
  description: string;
}

const logger = datadogLogs.createLogger('GeneralBusinessDetails');

const scrollIntoViewOnKeyDown = () => {
  window.scrollTo({
    top: document.body.scrollHeight,
    behavior: 'smooth',
  });
};

export const GeneralBusinessDetails: React.FC<GeneralBusinessDetailsProps> =
  observer(({ onNext, taxYear }) => {
    const classes = useStyles();
    const { app, chatbot, companyStore } = useCommonStores();
    const { surveyFlow, unifiedTaxCredits } = useTaxCreditsStores();
    const { client } = useLegacyClients();
    const { company, setCompany } = useContext(CompanyContext);
    const featureFlags = useFeatureFlags();
    const [questionFocus, setQuestionFocus] = useState<string>('');
    const [errorText, setErrorText] = useState<string>('');
    const [isError, setIsError] = useState<boolean>(false);
    const [hasLoadedSurveyAnswers, setHasLoadedSurveyAnswers] = useState(false);
    const [documents, setDocuments] = useState<Document[] | null>(null);
    const [filesToUpload, setFilesToUpload] = useState<
      ShortYearTaxReturnDocumentForUpload[]
    >([]);
    const [loadingPrefill, setLoadingPrefill] = useState(true);

    const shortYearFiles: string[] = useMemo(
      () =>
        documents
          ?.filter(
            (document) =>
              document.name &&
              document.description &&
              document.description === SHORT_YEAR_TAX_RETURN,
          )
          .map((document) => document.name)
          .filter(
            (documentName) => typeof documentName === 'string',
          ) as string[],
      [documents],
    );

    const allSurveysViewed = useMemo(() => {
      return companyStore.company.programs
        .filter((program) => program.taxYear === taxYear)
        .every(
          (program) =>
            program.subStage ===
              ProgramSubStageEnum.EXPENSE_CLASSIFICATION_SURVEY_SKIPPED ||
            program.subStage ===
              ProgramSubStageEnum.EXPENSE_CLASSIFICATION_READY_TO_SUBMIT ||
            (program.subStage === null &&
              program.stage === ProgramStageEnum.DISQUALIFIED),
        );
    }, [companyStore.company.programs, taxYear]);

    const {
      isSurveyComplete,
      isLoading,
      questionsToRender,
      surveyAnswers,
      addSurveyAnswers,
      saveAnswers,
      addSurveyAnswersWithoutRerender,
    } = useSurveyQuestions(
      SurveyNameEnum.GENERAL_BUSINESS_DETAILS,
      taxYear,
      {},
      renderTreeJson as unknown as CmsRenderTree,
    );

    useEffectOnce(async () => {
      await client.SetYeaPrefill(taxYear);
      await fetchCompanyDocuments();
      await app.common.companyStore.refreshCurrentCompany();
      setLoadingPrefill(false);
    });

    useEffect(() => {
      // scroll page down as user answers each question
      scrollIntoViewOnKeyDown();
    }, [surveyAnswers]);

    const fedRdProgram = companyStore.company.programs.find(
      (program) =>
        program.taxYear === taxYear &&
        program.name === ProgramNameEnum.FED_RD_TAX,
    );

    if (fedRdProgram) {
      useExpenseClassificationAnswerPrefill(
        SurveyNameEnum.GENERAL_BUSINESS_DETAILS,
        fedRdProgram,
        addSurveyAnswersWithoutRerender,
      );
    }

    useEffect(() => {
      if (!hasLoadedSurveyAnswers) {
        const taxYearQuestions =
          company?.qualificationQuestionsByYear?.[taxYear];
        if (taxYearQuestions) {
          const answers = Object.entries(taxYearQuestions).map(
            ([questionId, answerValue]) => ({
              questionId,
              answerValue,
            }),
          );
          addSurveyAnswersWithoutRerender(answers);
          setHasLoadedSurveyAnswers(true);
        }
      }
    }, [
      company,
      taxYear,
      addSurveyAnswersWithoutRerender,
      hasLoadedSurveyAnswers,
    ]);

    const fetchCompanyDocuments = async () => {
      const res = companyStore.accessToken
        ? await client.GetCompanyDocumentsPublic(companyStore.accessToken, {
            source: 'client',
          })
        : await client.GetCompanyDocuments({ source: 'client' });
      setDocuments(res.data?.documents || []);
    };

    const handleFileUploads = async () => {
      const uploadCompanyDocuments = companyStore.accessToken
        ? client.UploadCompanyDocumentsPublic(companyStore.accessToken, {
            documents: filesToUpload,
            emailOps: true,
          })
        : client.UploadCompanyDocuments({
            documents: filesToUpload,
            emailOps: true,
          });

      const { errorMsg } = await uploadCompanyDocuments;

      if (errorMsg) {
        logger.error(
          `GeneralBusinessDetails file upload error for companyId ${company.id}: ${errorMsg}.`,
        );
      } else {
        setFilesToUpload([]);
      }
    };

    const handleOnContinue = async () => {
      surveyFlow.setSurveyContinueLoading(true);
      await saveAnswers();
      await handleFileUploads();
      if (companyStore.accessToken) {
        unifiedTaxCredits.setShowInviteeSuccessModal(true);
      }

      try {
        const getPayrollImportStatus = client.CheckCreditEstimateLoaded;
        const { data } = await getPayrollImportStatus();

        const payrollImportStatus = data?.loadingStatus;

        if (
          featureFlags.enableYeaPrefill &&
          payrollImportStatus === 'completed'
        ) {
          // Refresh company to capture updated prefill company.qualificationQuestions
          // TODO: create a new endpoint to just get the updated qualification questions
          //  instead of pulling in the entire company object unnecessarily
          await client.CurrentLoggedInCompany().then((company) => {
            if (company) {
              setCompany(company);
            }
          });
        }
      } catch (e) {
        logger.warn(
          `Payroll Import incomplete. Unable to prefill for companyId ${company.id}.`,
        );
      }

      !companyStore.accessToken && onNext();
      surveyFlow.setSurveyContinueLoading(false);
    };

    const handleUpdatePartnerReferral = async (partner: string) => {
      await client.UpdateCompanyMisc({
        partnerReferral: partner,
      });
    };

    const handleAnswerChange = _.debounce(
      async (
        question: CmsQuestionData,
        answerValue: AnswerValueType,
        key: string,
      ) => {
        if (
          question.id === GraphCmsQuestionIdEnum.FOUNDED_YEAR &&
          typeof answerValue === 'number'
        ) {
          if (isValidYearFounded(answerValue)) {
            setErrorText('');
            setIsError(false);
            companyStore.accessToken
              ? await client.UpdateYearFoundedPublic(
                  companyStore.accessToken,
                  answerValue,
                )
              : await client.UpdateCompany({ yearFounded: answerValue });
          } else {
            if (isFutureYear(answerValue)) {
              setErrorText(
                'Founded year cannot be a future year. Please enter a valid year.',
              );
            } else {
              setErrorText('Please enter a valid founded year.');
            }
            setIsError(true);
            return;
          }
        }

        if (
          question.id === GraphCmsQuestionIdEnum.HOW_DID_YOU_HEAR_ABOUT_MST &&
          typeof answerValue === 'string'
        ) {
          const getAnswerText = question.answerIDs.find(
            (answer) => answer.id === answerValue,
          );

          const hasPartnerReferral = company.misc.partnerReferral
            ? company.misc.partnerReferral.length > 0
            : false;

          // does not save partnerReferral if exist in company.misc
          if (getAnswerText && !hasPartnerReferral) {
            const lowerCaseReferral = getAnswerText.text
              .replace(/\s+/g, '-')
              .toLowerCase();

            await handleUpdatePartnerReferral(lowerCaseReferral);
          }
        }

        question.answerValue = answerValue;
        addSurveyAnswers(question.id, key, [
          { questionId: question.id, answerValue: answerValue },
        ]);
        // save answers per question answered
        if (
          question.answerType === 'yes_or_no' ||
          question.answerType === 'multiple_choice' ||
          question.answerType === 'dropdown_select' ||
          question.answerType === 'dropdown_select_only'
        ) {
          saveAnswers && saveAnswers();
        }
      },
      500,
    );

    const onFileAdded = (description: string) => {
      return (fileHandle: FileHandle) => {
        const { file, name } = fileHandle;

        const fileToUpload = {
          file,
          description,
          name,
          source: DocumentSourceEnum.CLIENT,
        };

        setFilesToUpload((prevFilesToUpload) => [
          ...prevFilesToUpload,
          fileToUpload,
        ]);
      };
    };

    const handleFileRemoval = (fileHandle: FileHandle) => {
      const filteredFiles = filesToUpload.filter(
        (file) => file.name !== fileHandle.name,
      );
      if (filteredFiles.length !== filesToUpload.length) {
        setFilesToUpload(filteredFiles);
      }
    };

    const continueText = handleContinueText(
      companyStore.accessToken,
      allSurveysViewed,
      'Continue to Retirement Plan Credit',
    );

    const renderQuestions = () =>
      Object.keys(questionsToRender).map((key) => {
        return questionsToRender[key].map((question) => {
          return (
            <div
              key={question.id}
              onClick={() => setQuestionFocus(question.id)}
              onFocus={() => setQuestionFocus(question.id)}
              onBlur={() => setQuestionFocus('')}
              className={`${
                (question.answerType === 'dropdown_select' ||
                  question.answerType === 'dropdown_select_only') &&
                questionFocus === question.id
                  ? classes.dropdownSelect
                  : ''
              }`}
            >
              <Animate enter={['fade-in', 'from-bottom']} duration={0.5}>
                <SurveyQuestion
                  dataTestId={question.id}
                  answerType={question.answerType as AnswerType}
                  text={question.text}
                  subtitle={question.subtitle}
                  answerOptions={question.answerIDs}
                  onChange={(answer) =>
                    handleAnswerChange(question, answer, key)
                  }
                  onBlur={() => saveAnswers && saveAnswers()}
                  placeholder={question.placeholder}
                  answerValue={question.answerValue}
                  tooltip={question.tooltip}
                  questionTextSize={app.isMobile ? 15 : 18}
                  questionSubtitleSize={app.isMobile ? 13 : 15}
                  withCardMargin={false}
                  helpLinkText={question.helperText}
                  error={
                    question.id === GraphCmsQuestionIdEnum.FOUNDED_YEAR
                      ? isError
                      : false
                  }
                  errorText={
                    question.id === GraphCmsQuestionIdEnum.FOUNDED_YEAR
                      ? errorText
                      : ''
                  }
                  chatBotQuestion={chatbot.isAiQuestionEnabled(
                    question.aiQuestion,
                  )}
                  chatBotPrompt={() =>
                    chatbot.autoSendAiMessage(question.aiQuestion)
                  }
                  onHelpLinkClick='https://mainstreet1.my.site.com/help/s/article/Qualified-Retirement-Plans-for-Start-up-Cost-and-Auto-Enrollment-Tax-Credits'
                />
                {question.id ===
                  GraphCmsQuestionIdEnum.FILING_2_SHORT_YEAR_TAX_RETURNS &&
                  company?.qualificationQuestionsByYear?.[taxYear]?.[
                    GraphCmsQuestionIdEnum.FILING_2_SHORT_YEAR_TAX_RETURNS
                  ] ===
                    GraphCmsQuestionIdToAnswers[
                      GraphCmsQuestionIdEnum.FILING_2_SHORT_YEAR_TAX_RETURNS
                    ].YES && (
                    <FileUpload
                      label='Upload Document'
                      title={'Please upload a document containing:'}
                      subtitle="The current tax year's first short year gross receipts (or tax return if already exists)"
                      onFileAdded={onFileAdded(SHORT_YEAR_TAX_RETURN)}
                      onFileCancelled={handleFileRemoval}
                      onFileRemoved={handleFileRemoval}
                      previouslyUploadedFiles={shortYearFiles}
                    />
                  )}
              </Animate>
            </div>
          );
        });
      });

    return (
      <Flex
        direction='column'
        className={classes.root}
        data-testid={'general-business-details'}
      >
        <Flex className={classes.mainContent} direction='column'>
          <Animate enter={'fade-in'}>
            <SurveyFlowContainer
              title='General Business Details'
              onContinue={handleOnContinue}
              isDisabled={!isSurveyComplete || isError}
              isLoading={surveyFlow.surveyContinueLoading}
              continueText={continueText}
              currentPage={Page.assessmentBusinessDetails}
            >
              <Flex direction='column' gap={24}>
                <PrefillAlert />
                {isLoading || loadingPrefill ? (
                  <Spinner size='small' />
                ) : (
                  !_.isEmpty(questionsToRender) && renderQuestions()
                )}
              </Flex>
            </SurveyFlowContainer>
          </Animate>
        </Flex>
      </Flex>
    );
  });
