import React from 'react';
import { observer } from 'mobx-react';
import {
  useCommonStores,
  useFeatureFlags,
  useTaxCreditsStores,
} from 'stores/useStores';
import { Button } from 'component-library';
import { Page } from 'lib/constants';
import { TaxCreditPageBody } from './TaxCreditPageBody';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  continueBtn: {
    width: '108px',
  },
}));

export const IneligibleAssessments = observer(() => {
  const classes = useStyles();
  const { app, companyStore } = useCommonStores();
  const { unifiedTaxCredits } = useTaxCreditsStores();
  const featureFlags = useFeatureFlags();
  const showERCCredit =
    featureFlags.showERCCreditInEstimates &&
    unifiedTaxCredits.hasQualifyingERCProgram();

  /// Processing Tax Year Feature Flag value
  const PROCESSING_TAX_YEAR = featureFlags.currentProcessingTaxYear;

  const handleContinue = () => {
    app.history.push(`/${Page.sharedSavings}`);
  };

  const claimBannerTitle = showERCCredit
    ? `No ${PROCESSING_TAX_YEAR} tax credits`
    : 'Save more money, all year long';

  const claimBannerSubtitle = showERCCredit ? (
    <>
      {companyStore.currentCompany.legalName} is not eligible for the 2023 tax
      credits we currently offer. The good news: we believe you are eligible for
      Employee Retention Credits! Check out the ERC page for more details.
    </>
  ) : (
    <>
      Discover MainStreet&apos;s latest range of savings—unlock hidden tax
      savings for you and your employees.
    </>
  );

  const claimBannerButton = showERCCredit ? undefined : (
    <Button
      className={classes.continueBtn}
      variant='outlined'
      label='Learn more'
      onClick={handleContinue}
    />
  );

  const bodyTitle = 'Earn new credits';

  const bodySubtitle = (
    <span>
      You did not qualify for any {PROCESSING_TAX_YEAR} credits, but MainStreet
      is here to help you get tax benefits in the future! Here are a few things
      you can do to earn tax&nbsp;credits.
    </span>
  );

  return (
    <TaxCreditPageBody
      claimBannerTitle={claimBannerTitle}
      claimBannerSubtitle={claimBannerSubtitle}
      claimBannerButtonCta={claimBannerButton}
      bodyTitle={bodyTitle}
      bodySubtitle={bodySubtitle}
    />
  );
});
