import { makeStyles } from '@material-ui/core';
import { Flex, Grid, Spinner, Text } from 'component-library';
import { ExistingCustomerFreeCreditsModal } from 'components/ExistingCustomerFreeCreditsModal';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import TalkToAnExpertButton from 'components/util/TalkToAnExpertButton';
import {
  ExpectedCreditTypeEnum,
  GraphCmsQuestionIdEnum,
  GraphCmsQuestionIdToAnswers,
  Page,
  ProgramNameEnum,
  ProgramSubStageEnum,
  TermNames,
} from 'lib/constants';
import { HasManuallyAcceptedOrderForm, useEffectOnce } from 'lib/helpers';
import {
  AssessmentProgressStatus,
  CompanyData,
  ProgramData,
  TaxCreditAssessmentGeneralHygraphId,
  TaxCreditAssessmentProgramsHygraphId,
} from 'lib/interfaces';
import { observer } from 'mobx-react';
import { KeyDates } from 'pages/dashboard/taxCredits/KeyDates';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  useCommonStores,
  useCompany,
  useDashboardStores,
  useFeatureFlags,
  useTaxCreditsStores,
} from 'stores/useStores';
import {
  AssessmentInProgress,
  FinalEstimateList,
  IneligibleAssessments,
  ReviewInProgress,
  TaxCreditClaimComplete,
  TimeToFileStage,
} from './components';
import { PrepareForNextTaxYear } from './components/PrepareForNextTaxYear';

const useStyles = makeStyles(() => ({
  root: {
    padding: '40px 40px 80px',
  },
  gridContainer: {
    maxWidth: '1024px',
    width: '100%',
  },
}));

export const UnifiedTaxCreditsPage = observer(() => {
  const classes = useStyles();
  const { app } = useCommonStores();
  const { company } = useCompany();
  const { client } = useContext(Auth0FeatureContext);
  const { unifiedTaxCredits } = useTaxCreditsStores();
  const { modules } = useDashboardStores();
  const [federalRDPrograms, setFederalRDPrograms] = useState<ProgramData[]>([]);
  const [payrollTaxPrograms, setPayrollTaxPrograms] = useState<ProgramData[]>(
    [],
  );

  const featureFlags = useFeatureFlags();
  const history = useHistory();

  /// Processing Tax Year Feature Flag value
  const PROCESSING_TAX_YEAR = featureFlags.currentProcessingTaxYear;

  const assessmentSurveyRootPath = `/${Page.taxCredits}/${Page.assessment}/${PROCESSING_TAX_YEAR}`;
  const [inProgressRedirect, setInProgressRedirect] = useState<string>(
    `${assessmentSurveyRootPath}/${Page.rdConnectPayroll}`,
  );

  // Look at the company's qualification questions to see if the company answered
  // an onboarding question that indicates the company signed up for a specific
  // year
  const filingForSpecificYearAnswer =
    company.qualificationQuestionsByYear?.[PROCESSING_TAX_YEAR]?.[
      GraphCmsQuestionIdEnum.CREDIT_CLAIM_YEAR
    ];

  // Find answer that matches the currently supported tax year + 1
  const filingForNextYearAnswer =
    GraphCmsQuestionIdToAnswers[GraphCmsQuestionIdEnum.CREDIT_CLAIM_YEAR][
      PROCESSING_TAX_YEAR + 1
    ];

  // If the company has an onboarding answer and that answer is for the tax year
  // after the latest tax year we currently support, then they are filing for
  // next year
  const isFilingForNextTaxYear =
    filingForSpecificYearAnswer &&
    filingForNextYearAnswer &&
    filingForSpecificYearAnswer === filingForNextYearAnswer;

  const termsIncluded: string[] = [TermNames.CORE, TermNames.PROMOTIONAL];

  const enableCurrentYearYEA = featureFlags.enableCurrentYearYEA;

  useEffectOnce(async () => {
    if (
      company.qualificationQuestionsByYear?.[PROCESSING_TAX_YEAR]?.[
        GraphCmsQuestionIdEnum.CREDIT_CLAIM_YEAR
      ]
    ) {
      client.GetTermsAcceptanceByNames(termsIncluded).then(async (res) => {
        if (
          res?.data?.currentTermsAcceptance?.length === 0 &&
          featureFlags.showSavingsAgreement
        ) {
          app.history.push(`/${Page.savingsAgreement}`);
        }
      });
    }
    await client.SetYeaPrefill(PROCESSING_TAX_YEAR);
    await app.common.companyStore.refreshCurrentCompany();
  });

  useEffect(() => {
    if (company.programs) {
      const federalRDPrograms = company.programs.filter(
        (p) => p.name === ProgramNameEnum.FED_RD_TAX,
      );
      setFederalRDPrograms(federalRDPrograms);

      const payrollTaxPrograms = company.programs.filter(
        (p) => p.filingCreditType === ExpectedCreditTypeEnum.PAYROLL_TAX,
      );
      setPayrollTaxPrograms(payrollTaxPrograms);
    }
  }, [company.programs]);

  useEffectOnce(async () => {
    await unifiedTaxCredits.Determine8974Completion(
      company.id,
      PROCESSING_TAX_YEAR,
    );
  });

  const renderTaxCreditProcessStage = () => {
    switch (true) {
      /// all assessments are dq'ed
      case unifiedTaxCredits.hasAllAssessmentsDisqualified:
        return <IneligibleAssessments />;

      /// filing complete - prepare for next year
      case isFilingForNextTaxYear:
        return <PrepareForNextTaxYear taxYear={PROCESSING_TAX_YEAR + 1} />;

      case !enableCurrentYearYEA:
        return <PrepareForNextTaxYear taxYear={PROCESSING_TAX_YEAR} />;

      /// 8974 is complete
      case unifiedTaxCredits.hasCompleted8974 &&
        unifiedTaxCredits.hasFiledIncomeTaxStage:
        return (
          <TaxCreditClaimComplete
            taxYear={PROCESSING_TAX_YEAR}
            programs={company.programs}
            completed8974
          />
        );

      /// has filed taxes - payroll type
      case unifiedTaxCredits.hasFiledIncomeTaxStage &&
        payrollTaxPrograms.length > 0:
        return (
          <TaxCreditClaimComplete
            taxYear={PROCESSING_TAX_YEAR}
            isPayrollType
            programs={company.programs}
          />
        );

      /// has filed taxes - income type
      case unifiedTaxCredits.hasFiledIncomeTaxStage &&
        company.programs.length > payrollTaxPrograms.length:
        return (
          <TaxCreditClaimComplete
            taxYear={PROCESSING_TAX_YEAR}
            programs={company.programs}
          />
        );

      /// all assessments in client_review
      case unifiedTaxCredits.hasAllAssessmentsInClientReview:
        return <TimeToFileStage taxYear={PROCESSING_TAX_YEAR} />;

      /// all assessments in ms_review
      case unifiedTaxCredits.hasAllAssessmentsInReview &&
        company.paymentMethodOnFile &&
        company.misc?.submittedTaxYears?.includes(PROCESSING_TAX_YEAR) &&
        !company.programs
          ?.filter((p) => p.taxYear === PROCESSING_TAX_YEAR)
          .some(
            (p) =>
              p.subStage ===
              ProgramSubStageEnum.EXPENSE_CLASSIFICATION_SURVEY_SKIPPED,
          ):
        return <ReviewInProgress taxYear={PROCESSING_TAX_YEAR} />;
      default:
        return (
          <AssessmentInProgress
            redirectPath={inProgressRedirect}
            taxYear={PROCESSING_TAX_YEAR}
          />
        );
    }
  };

  const existingCustomer =
    HasManuallyAcceptedOrderForm(company.orderForms) &&
    featureFlags.showExistingCustomerFreeCreditModal;
  const showExistingCustomerModal =
    !company.misc?.existingCustomerAcknowledgedFreeCreditsModal &&
    existingCustomer &&
    modules.showExistingCustomerModal;

  const handleCustomerAcknowledgeFreeCredits = async () => {
    await client.UpdateCompanyMisc({
      existingCustomerAcknowledgedFreeCreditsModal: true,
    });
    modules.setShowExistingCustomerModal(false);
  };

  useEffect(() => {
    if (unifiedTaxCredits.hasJustCompletedAllAssessments) {
      const rootPath = `/${Page.taxCredits}/${Page.assessment}/${PROCESSING_TAX_YEAR}`;
      const disAllowedPaths = [
        `${rootPath}`,
        `${rootPath}/${Page.retirementPlan}`,
        `${rootPath}/${Page.healthcare}`,
        `${rootPath}/${Page.expenseClassificationCompanyDetails}`,
        `${rootPath}/${Page.expenseClassificationSuppliesServices}`,
        `${rootPath}/${Page.expenseClassificationEmployees}`,
        `${rootPath}/${Page.disabledAccess}`,
      ];

      if (disAllowedPaths.includes(history.location.pathname)) {
        history.replace(`/${Page.taxCredits}`);
      }
    }
  }, [
    history,
    unifiedTaxCredits.hasJustCompletedAllAssessments,
    PROCESSING_TAX_YEAR,
  ]);

  useEffectOnce(async () => {
    await unifiedTaxCredits.getTaxCreditAssessments(PROCESSING_TAX_YEAR);
    unifiedTaxCredits.getTotalCreditAmountCents(PROCESSING_TAX_YEAR);
  });

  useEffect(() => {
    if (!unifiedTaxCredits.loadingAssessments) {
      const taxYearAssessments =
        unifiedTaxCredits.assessmentByYear[PROCESSING_TAX_YEAR];

      if (taxYearAssessments && taxYearAssessments.length > 0) {
        const surveyInProgress = taxYearAssessments.find(
          (survey) => survey.status === AssessmentProgressStatus.IN_PROGRESS,
        );

        if (surveyInProgress) {
          const surveyId = surveyInProgress.id;
          const taxYearRDProgram = federalRDPrograms.find(
            (p) => p.taxYear === PROCESSING_TAX_YEAR,
          );

          switch (surveyId) {
            case TaxCreditAssessmentGeneralHygraphId.GENERAL_BUSINESS_DETAILS:
              setInProgressRedirect(`${assessmentSurveyRootPath}`);
              break;
            case TaxCreditAssessmentProgramsHygraphId[
              ProgramNameEnum.FED_RETIREMENT_CREDIT
            ]:
              setInProgressRedirect(
                `${assessmentSurveyRootPath}/${Page.retirementPlan}`,
              );
              break;
            case TaxCreditAssessmentProgramsHygraphId[
              ProgramNameEnum.FED_EMPLOYER_HEALTHCARE
            ]:
              setInProgressRedirect(
                `${assessmentSurveyRootPath}/${Page.healthcare}`,
              );
              break;
            case TaxCreditAssessmentProgramsHygraphId[
              ProgramNameEnum.FED_DISABLED_ACCESS
            ]:
              setInProgressRedirect(
                `${assessmentSurveyRootPath}/${Page.disabledAccess}`,
              );
              break;
            case TaxCreditAssessmentProgramsHygraphId[
              ProgramNameEnum.FED_RD_TAX
            ]:
              if (taxYearRDProgram) {
                if (
                  taxYearRDProgram.subStage ===
                    ProgramSubStageEnum.EXPENSE_CLASSIFICATION_COMPANY_DETAILS ||
                  taxYearRDProgram.subStage ===
                    ProgramSubStageEnum.EXPENSE_CLASSIFICATION_OVERVIEW ||
                  taxYearRDProgram.subStage ===
                    ProgramSubStageEnum.EXPENSE_CLASSIFICATION_SURVEY_SKIPPED
                ) {
                  setInProgressRedirect(
                    `${assessmentSurveyRootPath}/${Page.expenseClassificationCompanyDetails}`,
                  );
                } else if (
                  taxYearRDProgram.subStage ===
                  ProgramSubStageEnum.EXPENSE_CLASSIFICATION_RD_EXPENSES
                ) {
                  setInProgressRedirect(
                    `${assessmentSurveyRootPath}/${Page.expenseClassificationSuppliesServices}`,
                  );
                } else if (
                  taxYearRDProgram.subStage ===
                    ProgramSubStageEnum.EXPENSE_CLASSIFICATION_RD_EMPLOYEES ||
                  taxYearRDProgram.subStage ===
                    ProgramSubStageEnum.EXPENSE_CLASSIFICATION_READY_TO_SUBMIT
                ) {
                  setInProgressRedirect(
                    `${assessmentSurveyRootPath}/${Page.expenseClassificationEmployees}`,
                  );
                }
              }
              break;
          }
        } else {
          setInProgressRedirect(
            `${assessmentSurveyRootPath}/${Page.rdConnectPayroll}`,
          );
        }
      }
    }
  }, [
    assessmentSurveyRootPath,
    federalRDPrograms,
    unifiedTaxCredits.assessmentByYear,
    unifiedTaxCredits.loadingAssessments,
  ]);

  return (
    <div className={classes.root} data-testid={'unified-tax-credits'}>
      <Grid columns={12} className={classes.gridContainer} gap={40}>
        <Grid.Cell columns={12}>
          <Flex justifyContent='space-between' alignItems='center' gap={24}>
            <Text size={40} variant='medium'>
              Tax Credits
            </Text>
            {featureFlags.showTalkToAnExpertButton && <TalkToAnExpertButton />}
          </Flex>
        </Grid.Cell>
        <Grid.Cell columns={8}>
          <Flex direction='column' gap={32}>
            {unifiedTaxCredits.loadingAssessments ? (
              <Flex
                alignItems='center'
                justifyContent='center'
                padding={16}
                gap={8}
              >
                <Text>Retrieving available credits...</Text>
                <Spinner size='small' />
              </Flex>
            ) : (
              renderTaxCreditProcessStage()
            )}
          </Flex>
        </Grid.Cell>
        <Grid.Cell columns={4}>
          <Flex direction='column' gap={24}>
            {(unifiedTaxCredits.hasAllAssessmentsInClientReview ||
              unifiedTaxCredits.hasFiledIncomeTaxStage) && (
              <FinalEstimateList />
            )}
            {featureFlags.showKeyDatesModule &&
              federalRDPrograms.length > 0 &&
              !unifiedTaxCredits.hasAllAssessmentsDisqualified &&
              !unifiedTaxCredits.loadingAssessments && (
                <KeyDates
                  company={company as CompanyData}
                  federalRDPrograms={federalRDPrograms}
                  defaultExpand={true}
                />
              )}
          </Flex>
        </Grid.Cell>
      </Grid>
      {existingCustomer && (
        <ExistingCustomerFreeCreditsModal
          showExistingCustomerModal={showExistingCustomerModal}
          handleCustomerAcknowledgeFreeCredits={
            handleCustomerAcknowledgeFreeCredits
          }
          isTaxCreditPage
        />
      )}
    </div>
  );
});
