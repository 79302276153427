import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import {
  Animate,
  Button,
  Color,
  Divider,
  Flex,
  Heading,
  Text,
} from 'component-library';
import {
  useCommonStores,
  useFeatureFlags,
  useOnboardingStores,
} from 'stores/useStores';
import { ConnectPayroll } from 'products/onboarding/features/qualification/components';
import TitleHeader from 'components/TitleHeader';
import { ImportType } from 'lib/interfaces';
import { CompanyContext } from 'pages/CompanyRequired';
import { ConnectAccounting } from 'pages/dashboard/integrations';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';

const useStyles = makeStyles(() => ({
  wrapper: {
    backgroundColor: Color.neutral.white,
    position: 'relative',
  },
  mainContent: {
    width: '100%',
    minHeight: 'calc(100vh - 64px)',
    position: 'relative',
    zIndex: 1,
    display: 'flex',
  },
  content: {
    opacity: 1,
    transition: 'opacity .4s',
  },
  container: {
    maxWidth: '1200px',
    width: '100%',
    margin: '0 auto',

    '& span': {
      lineHeight: '1.4em !important',
    },
  },
}));

export const PayrollAccountingConnection = observer(() => {
  const classes = useStyles();
  const { app } = useCommonStores();
  const { company, setCompany } = useContext(CompanyContext);
  const { client } = useContext(Auth0FeatureContext);
  const { qualification } = useOnboardingStores();
  const featureFlags = useFeatureFlags();

  /// Processing Tax Year Feature Flag value
  const PROCESSING_TAX_YEAR = featureFlags.currentProcessingTaxYear;

  const onContinue = () => {
    app.history.push('/');
  };

  const refreshCompany = () => {
    return client.CurrentLoggedInCompany().then((company) => {
      if (company) {
        setCompany(company);
      }
    });
  };

  const isDisabled = !qualification.connectPayrollDoThisLater;

  return (
    <Animate enter={'fade-in'} duration={0.25} className={classes.wrapper}>
      <Animate enter={['fade-in', 'from-top']}>
        <TitleHeader
          borderBottom
          showExitButton
          onCancelExitText='Cancel'
          onExitLabel='Skip for now'
        />
      </Animate>
      <Animate className={classes.mainContent} enter={'fade-in'} delay={0.25}>
        <Flex
          className={classes.container}
          direction='column'
          padding={[40, 24, 80, 24]}
          gap={24}
        >
          <Heading tag={app.isMobile ? 'h4' : 'h3'}>
            Help MainStreet find you more savings opportunities
          </Heading>

          <Divider variant='no-bottom-margin' />
          <Flex direction='column' gap={8}>
            <Text
              text='Connect your payroll account'
              variant='medium'
              size={app.isMobile ? 18 : 23}
            />
            <Text
              text='Your payroll data helps us find and refine more credit opportunities in the future!'
              size={app.isMobile ? 13 : 15}
            />
          </Flex>

          <ConnectPayroll
            importType={ImportType.RetirementPlanQualification}
            taxYear={PROCESSING_TAX_YEAR}
            setDoThisLater={() =>
              qualification.setConnectPayrollDoThisLater(true)
            }
            subtitle='The easiest way for us to gather relevant information is directly from your payroll data.'
            doThisLaterModalTitle='You are not connecting your payroll?'
            doThisLaterModalContent='If you do not connect your payroll system, later in this process you will need to add all plan participants manually. We highly recommend connecting your payroll now.'
          />
          <Divider variant='no-bottom-margin' />
          <Flex direction='column' gap={8}>
            <Text
              text='Connect your accounting software'
              variant='medium'
              size={app.isMobile ? 18 : 23}
            />
            <Text
              text='Your accounting data helps us find and refine more credit opportunities in the future!'
              size={app.isMobile ? 13 : 15}
            />
          </Flex>
          <ConnectAccounting
            onAccountingConnected={refreshCompany}
            returnPage={'eligibility'}
            isRequired={false}
            title='Connect accounting software'
            subtitle='The easiest way for us to find more savings for you.'
          />
          <Button
            flexAlignSelf='flex-end'
            label='Continue'
            disabled={isDisabled}
            onClick={onContinue}
          />
        </Flex>
      </Animate>
    </Animate>
  );
});
